.textStyle {
    color: gray
}
.indicator {
    background-color: #000
}
.tabStyle , .MuiButtonBase-roo {
    color: gray;
}
.btnFilled{
    
    background-color: #864499 !important;
    color: #fff !important;
    padding: 10px 60px !important;
    border-radius: 8px !important;
  }
.btnFilled:hover {
    background-color: #864499;
    color: #fff;
}
.btnOutlined{
     background-color: #fff !important;
     color: #864499 !important;
     padding: 10px 60px !important;
     border :2px solid #864499 !important;
     border-radius: 8px !important;
}
.btnOutlined:hover {
    background-color: #fff !important;
     color: #864499 !important;
}
.modalDiv {
    padding : 10px;
    gap: 10px;
    width : 350px !important;
   
}
.grayText{
    color : "gray";
    margin :"10px 0px 20px 0px"
}
.textbox {
    width: 850px;
    margin-bottom: 10px !important; 
}